import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
   <div className='container'>
      <div className='col'>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
      <div className='col'>
        <div className="grid-test"></div>
      </div>
      <div className='col'>
        <div className="grid-test"></div>
      </div>
      <div className='col'>
        <div className="grid-test"></div>
      </div>
    </div>
    <div className='container'>
      <div className='col col-third'>
        <div className="grid-test"></div>
      </div>
      <div className='col col-third'>
        <div className="grid-test"></div>
      </div>
      <div className='col col-third'>
        <div className="grid-test"></div>
      </div>
    </div>
    <div className='container'>
      <div className='col col-half'>
        <div className="grid-test"></div>
      </div>
      <div className='col col-half'>
        <div className="grid-test"></div>
      </div>
    </div>
  </>
)

export default NotFoundPage
